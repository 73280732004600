.Panel-container{
    width: 100%;
    margin-top: 200px;
}

.Panel-container-request-table{
    padding: 0px 50px;
}

.Panel-transfer-request-list{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.Panel-transfer-request-list-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    background-color: #02613a;
    color: #ffffff;
    height: 40px;
}

.selectAllContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.selectAllContainer input{
    margin-right: 5px;
}

.Panel-container-filter{
    display: flex;
    padding: 30px 50px;
}

.Panel-blur-background{
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: transparent;
    backdrop-filter: blur(10px);
    left: 0px;
    padding-top: 50px;
    min-height: 900px;
}

.Panel-administradorpage{
    padding: 0px 40px!important;
    background-position: 50% 25%!important;
}

.inputExtra{
    width: 180px!important;
}

.notFoundMessage{
    width: 100%;
    text-align: start;
    display: flex;
    flex-direction: row;
    justify-content: center;
}