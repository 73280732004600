.CheckNumber-winning-number-container{
    display: flex;
    align-items: center;
    flex-direction: column;
}

.CheckNumber-title{
    text-align: center!important;
}

.CheckNumber-legacy-container{
    position: absolute;
    right: 12px;
    margin-top: -12px !important;
    z-index: 10;
}

a {
    color: white;
}