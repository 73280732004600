.Login-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15%;
    flex-direction: column;
}

.Login-container-element{
    margin-top: 10px;
    flex-direction: column;
    display: flex;
}

.Login-container-button{
    margin-top: 10px;
    width: 80px;
    height: 25px;
    display: flex;
    align-items: center;justify-content: center;cursor: pointer;
    background-color: #efefef;
    border: 1px solid gray;
    border-radius: 10px;
}

.Login-error-message {
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Login-error-message{
    font-size: 12px;
    color: red;
    margin-top: 10px;
}

.Login-logincard{
    width: 400px;
}

.Login-submit-button{
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Login-form-block{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 40px;
}

.Login-loginbody{
    height: 100%;
}

.Login-image-4{
    cursor: pointer;
}
