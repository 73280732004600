.TransferRequest-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0px;
}

.TransferRequest-container-contact {
    display: flex;
    flex-direction: column;
}

.TransferRequest-container-contact-info {
    display: flex;
    flex-direction: row;
}

.TransferRequest-container-contact-info span {
    margin-right: 5px;
}

.TransferRequest-adminclientcomponent{
    padding: 15px 10px!important;
}

.TransferRequest-infocontactoclienteExtra{
    margin-bottom: 0px!important;
}

.TransferRequest-infocontactoclienteExtra2{
    margin-top: 16px;
}

.TransferRequest-fullname{
    cursor: pointer;
}

.bodytextGreen{
    color: #02613a;
}