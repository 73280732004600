.EditContact-formblockdatosusuario{
    z-index: 10;
    position: absolute;
    background-position: 6% 39%, 0px 0px, 100% 50%!important;
    left: calc(100vw/2 - 250px) !important;
    -webkit-box-shadow: 4px 9px 29px -12px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 4px 9px 29px -12px rgba(0, 0, 0, 0.75);
    box-shadow: 4px 9px 29px -12px rgba(0, 0, 0, 0.75);
}

.EditContact-buttons{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.message{
    font-size: 14px;
    font-family: Poppins, sans-serif;
    font-weight: 500;
    margin-bottom: 10px;
    text-align: center;
}